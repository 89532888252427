// O LIST PADRAO ESTA NA MAIN.SCSS

//INPUT SEARCH
.search-box{
	padding:13px 20px;
	input[type=text]{outline:none}
		input.search-style {
		    height: 40px;
		    width:100%;
		    border-radius: 15px;
		    text-align: center;	  
		    background-color: $bg-menu;
		    border: 0px;
		    color: #5f7185;
		}
}
//FILTERS CHANNEL
.list-all{
	margin:0 auto;
	height: 40px;
	color: #fff;
	font-size: 25px;
	background:#29323b;
	margin:5px 0 24px 0;
	.icons-list{		
		text-align: center;	
		cursor:pointer;			
	}
}
.filter-active{
	background-color: #38414a;
	color:$color2;
}
.notification{
	width: 25px;
    height: 25px;
    background-color: #33a8df;
    border-radius: 50%;
    margin: 19px 3px;
    line-height: 27px;
    text-align: center;
	p{
		font-size: 12px;
		color: #fff;

	}
}
//FILTERS CHANNEL
.button-group{
	margin: 0 auto;
	width: 100%;
	button{
		background-color: $bg-menu;
		width: 100%;
		height: 30px;
		margin-bottom: 20px;
		color: #fff;
		padding: 5px;
		font-size: 15px;
		&:hover{
		background-color: $color;
	}
	}
}
