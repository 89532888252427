.profile{
	.init-cv{
		width: 89%;
    	margin-top: 30px;
    	background-color: $bg-menu;
    	color: white;
    	font-weight: 450;
    	cursor:pointer;
    	&:hover{
    		background-color: $color;
    	}
	}
	p{
		margin:0!important;
		overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
	.block-contact{
		margin: 5px;
	    margin-top: 20px;
	    width: 95%;
   		font-size: 35px;
	    background-color: $bg-menu;
	    color: $color;
	    cursor:pointer;
	    &:hover{
	    	color: #923710;
	    }
	}
}