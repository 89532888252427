//Section homepage
.main{
 	background:#4b5a6b;
}

.no-pading{
	padding:0px;		
}

//Section login and register and appDownload
.height-login{
	//height: 600px;
	background-color:$bg-secao;
}
//Section login and register and appDownload


//Section Contacts,Channel and settings
.content-view{		
	background-color:$bg-secao;
	overflow-x: hidden;
}
.content-view::-webkit-scrollbar{
	 background: rgba(0,0,0,0.1);
	 width: 5px;
}
.content-view::-webkit-scrollbar-thumb{
	background: #2e789d;
}
//Section Contacts,Channel and settings


//Section messages
.content-messages{
    // height: 832px;
	background-image:url('../../assets/img/bg-message.png')
}
//Section messages

// LIST CHANNELS AND CONTACTS //
//LIST
a.click-list {
	padding:10px;
	text-decoration:none;
	.text-list{
		padding: 24px 0 20px 15px;
		h3 {
			font-size:15px;
			margin-bottom:0px;
			overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    text-transform: capitalize;
		    letter-spacing: 1.2px;
		}
		p{
			font-size:12px;
			color:#d4d4d4;
		}
	}
	.img-profile {	
		> img {
			// margin-top: 5px;
			border-radius:50px;
			border:3px solid #3d4751;				
		}		
	}
	.info-list{
		text-align:center;
		// padding-top:5px;
		padding-top:22px;
		.first-hour{
			font-size:13px;
			margin-bottom:8px;
			color:$color2;
			font-weight:bold;
		}
		span.icon-block{
			color:$color;
		}
	}
	&:hover {
		background-color:$bg-menu;
	}
}