@mixin button{
    width:110px;
    border:none;
    border-radius: 5px;
    background-color: $color2; 
    margin: 40px 0 60px 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    &:hover{
        background-color:$color;
    }
} 
@mixin inputDefault {
    width:100%;
    height: 35px;
    background-color: #faffbc;
    border-radius: 5px;
    border:none;    
    padding: 8px;
    margin-bottom: 10px;
}

@mixin h2Default {
    color:$color;
    padding-top: 150px;
    margin-bottom: 40px;
    text-transform: uppercase;
}