.box-download{
	text-align:center;
	margin-top:100px;		
	h2{
		color: #fff;		
		font-weight: normal;
	}
	p{
		font-weight: bold;		
		color:$color;
		text-transform: uppercase;;
		font-size: 30px;
	}	
	.box-icons{
		margin:80px 0px;
		.apple,.android{
			width:100px;
			margin:0 auto;
			text-align:center;
			p{
				font-size:17px;
			}								
		}
	}

	.logo-footer{
		text-align:center;
		margin:0 auto;
		img{
			width:400px;			
		}
	}
	
}
