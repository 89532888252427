.group-info{
	h1{
		font-size: 25px;
		color: $color;
		text-transform: capitalize;
		margin-bottom: 20px;
	}
	p{
		color:$color2;
		font-size: 20px;
	}
	input{
		background-color: $bg-menu;
		border: none;
		width: 90%;
		padding: 10px;
		font-size: 20px;
		color: $color;
	}
	i{
		background-color: $bg-menu;
		color: $color;
		width: 10%;
		height: 50px;
		padding: 18px 5px;
	}
}
.list-participe{
	margin-top:20px;
	.infos{
		background-color:$bg-menu;
		padding:4px;
		border-bottom:1px solid #2f3841;
		.img-contact{
			margin-left: 10px;
		    min-width: 40px;
		    max-width: 55px;
			img{
				border-radius:100%;
				border: 4px solid #2f3841;
			}
		}	
		.info-contact{
		    color: #fff;
		    font-size: 15px;
		    letter-spacing: 1px;
		    text-transform: capitalize;
		    margin: 17px 0 0 10px;
    		text-align: left;
			p{
				margin:0px!important;
			}
		}
		.admin-group{		
				margin-left: 15px;
				color: #9ab1ca;		
				font-size: 10px;
			}

	}	
}
#modal-time{
	margin: 15px 0;
	width: 100%;
	background-color: $bg-menu;
	p:first-child{
		font-size: 14px;
		color: #9ab1ca;
		margin: 5px 0px!important;
	}
	select{
		width: 90%;
		padding: 5px;
		margin-top: 15px;
		background-color: $bg-secao;
		color: #fff;
		border: none;
		font-size: 15px;
		cursor: pointer;
		option{
			font-size: 15px;
			color: #fff;
			background-color: $bg-secao;
		}
		option:hover,
		option:focus,
		option:active,
		option:checked {
		  background-color: $color;
		  color: #000;
		}
	}
	button{
		margin:20px 0;
		background-color:#fb733d;
		color: $bg-menu;
		cursor: pointer;
		padding: .2rem 1rem;
		&:hover{
			background-color: #f75701;
		}
		p{
			text-transform: uppercase;
			color: $bg-menu!important;
		}
	}
}
.buttons{
	margin-top: 15px;
}
button.btn.btn-tamanho {
    width: 90%;
    margin: 5px;
    background-color: $bg-menu;
    color: #fff;
    font-size: 17px;
    &:hover{
    	background-color: $color;
    }
}
.btn-save{
	text-align: left;
}
.btn-enviar-group{

    input {
    position: relative;
    bottom: 67px;
    /* right: 0px; */
    border-radius: 3px;
    background-color: #33a8df;
    color: #29323b;
    font-size: 12px;
    border: none;
    width: 90%;
    height: 38px;
    }
    i{
	    position: relative;
	    bottom: 35px;
	    color: #29323b;
	    z-index: 999;
    }
}

@media(max-width:1199px){
	.infos{
		text-align:center;
		.img-contact{
			margin:0 auto;
		
		}	
	}
}