.list-settings > li {
	background-color:$bg-secao;
	font-size: 15px;
    letter-spacing: 0.5px;
	a:hover{
		color:$color!important;
	}
	a{
		cursor: pointer;
	}
	i{
		color: $color;
		font-size: 20px;
		padding: 7px 30px 10px 0;
	}
}