
//IMAGEM DEFAULT
.message-default{
	width: 100%;
}
.img-default {
	background-color: #EBEBEB;
	width: 100%; 
	height: 100%; 
	z-index: 90; 
	position:absolute;
	top: 0; 
	left: 0;
	text-align: center;
	>img {		
		max-width: 832px; 
		margin-top: 10%;		
	}
}

//TOP
textarea:focus, input:focus, select:focus {
    outline: 0;
} 
.top-bar-message{
	border-bottom: 1px solid #dde1e3;
	background-color: #fff;
	padding: 5px;
	font-family: $font-antipasto;

	.img-contact{	
    	padding: 5px 10px;
	    max-width: 80px;
	    float: left;
	    cursor: pointer;
		> img {
			border-radius:50px;		
			border: 5px solid #ebebeb;
		}
	}
	.info-message{
		padding-top: 16px;
		padding-left: 10px;
		float: left;
		h3{
			font-size: 22px;
   			margin-top: 11px;
			color: #414c58;
			font-weight: bold;
			line-height: 0;
			text-transform: capitalize;
		}
		p{
			color: #a1a1a1;
		    display: inline;
		    font-size: 13px;		   
		}
	}
	.icons-message{
		padding-top: 18px;   
		text-align: right;
		width: 100%;
		height: 100%;
		cursor: pointer;
		color: $bg-secao;
		i#search,
		i.fa.fa-ellipsis-v{
			margin-right: 20px;
		}
		i#search {
		    font-size: 30px;
			position: relative;
			top: -4px;
		}
		i.fa.fa-ellipsis-v {
		    font-size: 36px;
		}
	}	
  	.menu-msg{
  		position: absolute;
	    width: 100%;
	    background-color: #fff;
	    top: 80px;
	    right: 0px;
	    z-index: 999;
	    border: 1px solid #f1f1f1;
	    padding-left: 25px;
	    -webkit-box-shadow: 0px 17px 63px -15px rgba(0,0,0,0.26);
		-moz-box-shadow: 0px 17px 63px -15px rgba(0,0,0,0.26);
		box-shadow: 0px 17px 63px -15px rgba(0,0,0,0.26);
	    .item {
	    	border-color: #ddd;
	    	color: #4b5a6b;
	    	display: block;
	    	padding: 10px 0;
	    	font-size: 14px;
		    &:hover{
		    	cursor:pointer;
		    	color: $color;
		    }
		}
  	}
    .search-message{
	   	right: 100px;
	    top: 37px;
	    width: 500px;
	    position: absolute;
	    display: none;
	    border: 2px solid #f1f1f1;
    	border-radius: 10px;
    }
    #search-message {
    	color: #4b5a6b;
	    background: #f1f3f5;
	    border: none;
	    padding: 2px;
	    max-width: 250px;
	    text-align: center;
	    font-size: 13px;
	}

	.block-part{
		max-width: 500px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    color:#d4d4d4;
	    text-transform: capitalize;
	}
	span.participantes{
		overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}	

} // END TOP BAR
	.messages-all::-webkit-scrollbar{
		 background: rgba(0,0,0,0.1);
		 width: 5px;
	}
	.messages-all::-webkit-scrollbar-thumb{
		background: $color;
	}

// SECAO MESSAGES
.progress{
	background-color: #C6C6C6; 
	border-radius: 50%;
	width: 50px; 
	height:50px;
	position: relative;
}
.progress span{
	position: absolute;
	top:50%;
	left:50%;
	font-size: 2.0mm;
	margin-top:-3.0mm;
	margin-left:-2.0mm;
}
.messages-all{
	padding:10px 15px 30px 15px;
	overflow-x:hidden;
	height: 82%;
	.button-view{
		text-align:center;		
		.button-danger{
			background-color:$color;
			opacity: 0.7;
			border:$color 1px solid;
			border-radius:20px;
			color:white;		
			font-size:14px;
			font-weight:500;
			text-align:center;
			cursor: pointer;
			height: 20px;
			padding-top: 2px;
		    padding-right: 24px;
		    padding-bottom: 22px;
			i{
				font-size: 12px;
				padding: 5px 5px 5px 0;
			}
			
		}
	}
	
	.chat{
		padding: 3px;		
		.img-message{
			max-width:55px;
			display: inline-block;		
			> img {
				border-radius:100%;
			}
		}
		.message{
			font-size: 14px;
			height:auto;
		    display: inline-block;
		    background-color:#fff;
		    -webkit-border-radius: 20px;
			-webkit-border-bottom-left-radius: 0;
			-moz-border-radius: 20px;
			-moz-border-radius-bottom-left: 0;
			border-radius: 10px 10px 10px 0;
		    padding:8px 15px;
		    color: #4a5766;
		    p{
		    	margin-bottom: 0;
		    }
		    p:first-child{
		    	font-size: 15px;
		    	color: #bf0811;
		    	text-transform: capitalize;
		    }
		}  
	}

	// MESSAGE RIGHT
	.right{
		padding: 10px;	
		.img-message{
			float:right;
			margin-left:5px;
		}
		.message{
			float:right;
			border-radius: 10px 10px 0 10px;
			-moz-border-radius: 20px;
			-moz-border-radius-bottomleft: 0;
			background-color:$chat-right;
			color: #4a5766;
		}
	}
	.hour-message{
		font-size:10px;
	}
	.btn-download{
		margin-bottom: 5px;
		cursor:pointer;
		background-color:$color;
	    font-size: 10px;
	    float: right;
	    clear: both;
	    padding: 5px;
	    text-transform: uppercase;
	    border: none;
	}	
}
p.hour-message.ng-binding {
    color: #696969;
}
@media (max-height:700px){
	.messages-all{
		height:72%;
	}
	.img-default{
		img{
			width: 357px!important;
			margin-top: 10%;
		}
	}
}
@media (max-height:600px){
	.img-default{
		img{
			width: 250px!important;
			margin-top: 10%;
		}
	}
}

@media (max-height:470px){
	.img-default{
		img{display:none;}
	}

}