	.login {
	max-width:400px;
	margin:0 auto;
	h2{
		@include h2Default;
	}
	label{
		text-align: left;
		color:#9ab1ca;
		font-size: 15px;
	}
	p{
		color:$color2;
	}
	input{		
		@include inputDefault;
		background-color: #3a4450;
		color: #fff;
	}
	.button-enter{
		@include button;
	}
	a{
		font-size: 30px;
		text-transform: uppercase;
		color: $color!important;

	}
	img{
		width: 25%;
		text-align: center;
		margin: 90px 0 200px 0;
	}
	.error-password{
		color: #FF8C00;
	    font-size: 15px;
	    font-weight: normal;
	}
}